var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", { staticClass: "title" }, [
      _vm._v("\n        " + _vm._s(_vm.title) + "\n    "),
    ]),
    _vm._v(" "),
    _vm.subtitle
      ? _c("h2", { staticClass: "subtitle" }, [
          _vm._v("\n        " + _vm._s(_vm.subtitle) + "\n    "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("hr"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }