<template>
    <div>
        <h1 class="title">
            {{title}}
        </h1>
        <h2 class="subtitle" v-if="subtitle">
            {{subtitle}}
        </h2>
        <hr>
    </div>
</template>
<script>
export default {
    props: ['title', 'subtitle']
}
</script>