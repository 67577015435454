//our CSS file
import "./shadowcatplatform.scss";
//fontawesome
import "@fortawesome/fontawesome-free/js/all.min.js";
//vue
import Vue from "vue";
window.Vue = Vue;

//utils
import init_custom_comps from "./components/imports.js";
init_custom_comps(Vue);

import headerComponent from "./header-component.vue"
Vue.component('header-component',headerComponent)

Vue.use({
    install: function(Vue){
        Vue.prototype.$getUrl = window.$getUrl;
    }
});

//axios
window.axios.interceptors.request.use(function (config) {
    if(['post','delete'].includes(config.method) && config.url.startsWith('/')){
        config.headers['X-CSRFToken'] = window.getCookie('csrftoken');
    }
    return config;
});